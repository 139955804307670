import React, { FC, useEffect, useRef } from 'react';
import { DateTime } from 'luxon';
import { Article, GlossaryTerm } from 'superclient';
import MetaModule from '../components/core/meta-module';
import EditorialModules from '../components/editorial/editorial-modules';
import EditorialContributorIcon from '../components/editorial/editorial-contributor-icon';
import { highlightGlossaryTerms } from '../components/core/glossary-highlighter';
import EditorialFeaturedContent from '../components/editorial/editorial-featured-content';
import EditorialSponser from '../components/editorial/editorial-sponser';

const TemplateArticle: FC<{
  pageContext: { article: Article; glossaryTerms: GlossaryTerm[] };
}> = ({ pageContext: { article, glossaryTerms } }) => {
  const {
    title,
    slug,
    tags,
    content,
    credits,
    featuredContent,
    featuredImage,
    date,
    sponsored,
    sponsoredTextOverride,
    metaCard,
    cardPreviewSnippet
  } = article;

  const bodyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (bodyRef.current) {
        highlightGlossaryTerms(bodyRef.current, glossaryTerms);
      }

      const sharable = require('sharable/browser');
      // eslint-disable-next-line
      const share = sharable.default();
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://platform.twitter.com/widgets.js';
      document.body.appendChild(s);
    }, 500);
  }, [glossaryTerms]);

  return (
    <div>
      <MetaModule
        title={metaCard?.fields.openGraphTitle || title}
        description={metaCard?.fields.openGraphDescription || cardPreviewSnippet}
        path={`/editorial/${slug}`}
        customImage={{
          src: metaCard?.fields.twitterCardShareImage
            ? `https:${metaCard?.fields.twitterCardShareImage.fields.file.url}`
            : `https:${featuredImage?.file.url}`
        }}
      />
      <EditorialFeaturedContent featuredContent={featuredContent} />
      <div className="article__header container--xl ma p1">
        <h2
          className="sans caps m0 p0"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {tags &&
          tags.map((tag, i) => (
            <span key={Math.random()} className="caps mr05 xsmall">
              {tag}
              {i !== tags.length - 1 ? ',' : ''}
            </span>
          ))}
      </div>
      <div className="bcg">
        <div className="container--xl ma p1 py1 f jcb fw x aic">
          <div className="article__con-bar x f fr fw jcb s12 caps">
            <div className="article__contributors f fc jcs">
              {credits &&
                credits.map((credit) => (
                  <div key={credit.id} className="x mr15">
                    <div className="f aic">
                      {credit.type && (
                        <EditorialContributorIcon type={credit.type} />
                      )}
                      {credit.name}
                    </div>
                  </div>
                ))}
            </div>
            <div className="article__share">
              <span className="mr1">Share</span>
              <a
                href="#0"
                data-url={`https://www.supercluster.com/editorial/${slug}`}
                data-social="twitter"
              >
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
                </svg>
              </a>
              <a
                href="#0"
                data-url={`https://www.supercluster.com/editorial/${slug}`}
                className="ml1"
                data-social="facebook"
              >
                <svg width="24" height="24" viewBox="0 0 24 24">
                  <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="mb025">
            {date && (
              <div>
                <span className="xsmall caps">
                  {DateTime.fromISO(date).toFormat('DDD')}
                </span>
                <span className="ml2 xsmall caps">
                  {DateTime.fromISO(date).toFormat(`h:mm a ZZZZ' (UTC 'Z')'`)}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="article__content" ref={bodyRef}>
        <EditorialModules modules={content.content} />
        <EditorialSponser
          sponsered={sponsored}
          sponsoredTextOverride={sponsoredTextOverride}
        />
      </div>
      <div className="bcg">
        <div className="container--xl ma p1 py15 f jcb fw article__con-bar x aic">
          <div>
            <div className="article__contributors f fw jcs">
              {credits &&
                credits.map((credit) => (
                  <div key={credit.id} className="s12 x mr15 caps">
                    <div className="f aic">
                      {credit.type && (
                        <EditorialContributorIcon type={credit.type} />
                      )}
                      {credit.name}
                    </div>
                  </div>
                ))}
            </div>
            <div className="article__posted">
              {date && (
                <div>
                  <span className="xsmall caps">
                    {DateTime.fromISO(date).toFormat('DDD')}
                  </span>
                  <span className="ml2 xsmall caps">
                    {DateTime.fromISO(date).toFormat(`h:mm a ZZZZ' (UTC 'Z')'`)}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="s12 caps article__share">
            <span className="mr1">Share</span>
            <a
              href="#0"
              data-url={`https://www.supercluster.com/editorial/${slug}`}
              data-social="twitter"
            >
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />
              </svg>
            </a>
            <a
              href="#0"
              data-url={`https://www.supercluster.com/editorial/${slug}`}
              className="ml1"
              data-social="facebook"
            >
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateArticle;
